import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import { StrongWhite } from "../../../components/Textstyles/Styles";

export const frontmatter = {
  title: "Día 71",
  week: "Semana 11",
  day: "23",
  month: "May",
  monthNumber: "05",
  date: "2020-05-23",
  path: "/cronologia/semana-11#dia-23-may/",
};

const Day71 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 455 nuevos positivos y 52 personas fallecidas. Permanecen
          hospitalizadas 124.521 personas, 95 menos que el día anterior. El
          número de casos ingresados en la UCI asciende a 11.454, lo que supone
          un aumento de 9 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 %.
        </ModText>
        <ModGraph
          name="22-05-es-fallecidos"
          alt="fallecios por covid en españa por semanas"
        />

        <ModDailyHighlight>
          Hay más de <StrongWhite>150 proyectos de vacunas</StrongWhite> frente
          a COVID-19 en todo el mundo.
        </ModDailyHighlight>

        <ModText>
          El Ministerio para la Transición Ecológica y el Reto Demográfico ha
          impulsado una serie de medidas para la gestión de residuos de forma
          responsable ante el brote de COVID-19. En esta{" "}
          <InlineLink link="https://www.miteco.gob.es/es/ministerio/medidas-covid19/residuos/default.aspx">
            guía
          </InlineLink>{" "}
          estan disponibles las instrucciones para llevarlas a la práctica.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day71;
